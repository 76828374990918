<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <a-btn-save @click="post()" />
      <a-btn-refresh @click="fitchData()" />
    </template>
    <TreeBlock @change="onChange($event)" @copy="onCopy($event, index)" @rename="onRename($event)" @remove="onRemove($event)" @create="onCreate($event)" :value="data" />
    <portal to="v-main"> </portal>
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    TreeBlock: () => import("@/views/tree/treeBlock"),
  },
  data() {
    return {
      title: "",

      url: "/config",
      defaults: {},
      data: {},
    };
  },
  created() {
    this.$root.title = "Настройки";
    this.fitchData();
  },
  watch: {},
  methods: {
    async fitchData() {
      const resp = await this.$axios.get(this.url);
      if (!(resp.data.status == "ok")) {
        this.$root.$emit("show-info", {
          text: "Ошибка....",
        });
        return;
      }

      //this.data = JSON.parse(resp.data);
      this.data = resp.data.config;
      // this.data.models["taskUserConfigGoods"] = taskUserConfig;
      //console.log(this.data.models["goods"]);
      // this.data.models["cron"] = this.cron;
      // this.data.models["events"] = this.events;
      //      this.data.models["finance"] = this.finance;

      //console.log(this.data);
    },

    onCopy(e) {
      console.log("onCopy:", e);
      //this.data[e.i] = e.v;
    },
    onRename(e) {
      console.log("onRename:", e);
      //this.data[e.i] = e.v;
    },
    onChange(e) {
      console.log("onChange:", e);
      if (e.i == -1) {
        this.data = e.v;
        return;
      }
      this.data[e.i] = e.v;
    },
    onCreate(e) {
      console.log("onCreate:", e);
      this.data[e.i] = e.v;

      // this.data[e.i] = e.v;
    },
    onRemove(e) {
      console.log("onRemove:", e);
      this.data[e.i] = e.v;
    },
    async post() {
      this.$root.$emit("show-info", { type: "error", text: "Старая функция" });
      return;
      //this.data.models = { users: models.users };
      let data = { config: this.data };

      this.loading = true;
      let response = await this.$axios.post(this.url, data);
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Данные записаны",
        });
      }
    },
  },
};
</script>
